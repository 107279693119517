<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '添加子技能' : '编辑子技能'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="子技能主题" prop="abilityId">
        <el-select v-model="formData.abilityId" :disabled="!mode" placeholder="请选择子技能" filterable>
          <el-option v-for="item in skillList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="权重" prop="weight">
        <el-input-number v-model="formData.weight" :min="0" :max="999" :controls="false" placeholder="请输入权重" />
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input
          v-model="formData.description"
          type="textarea"
          :rows="2"
          placeholder="请输入子技能描述"
          style="height: auto"
          :maxlength="300"
        ></el-input>
      </el-form-item>
      <el-form-item label="合格阈值" prop="qualifiedRate">
        ≥
        <el-input-number v-model="formData.qualifiedRate" :min="0" :max="999" :controls="false" class="rate-input" placeholder="请输入阈值" />
        %
      </el-form-item>
      <el-form-item label="推荐建议" prop="unqualifiedRemark">
        <el-input
          v-model="formData.unqualifiedRemark"
          type="textarea"
          :rows="2"
          placeholder="请输入推荐建议"
          style="height: auto"
          :maxlength="300"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import newPostApi from '@api/newPost'
import abilityApi from '@api/ability'
import YTIcon from '@components/common/YTIcon'
import { floatMultiply } from '../../../util/util'
export default {
  name: 'PostSubSkillCUDialog',
  components: { YTIcon },
  data() {
    const validateWeight = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error('权重不能为0'))
      } else {
        callback()
      }
    }
    const validateRate = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error('合格阈值不能为0'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      formData: {
        abilityId: '',
        weight: '',
        description: '',
        qualifiedRate: '',
        unqualifiedRemark: '',
        mainSkillId: '',
        subSkillId: '',
        postId: ''
      },
      ruleValidate: {
        abilityId: [{ required: true, message: '请选择子技能主题', trigger: 'change' }],
        weight: [
          { required: true, message: '权重不能为空', trigger: 'blur' },
          { validator: validateWeight, trigger: 'blur' }
        ],
        qualifiedRate: [
          { required: true, message: '合格阈值不能为空', trigger: 'change' },
          { validator: validateRate, trigger: 'blur' }
        ]
      },
      loading: false,
      skillList: []
    }
  },
  methods: {
    open(mode, data) {
      this.visible = true
      this.mode = mode
      this.formData.mainSkillId = data.mainSkill.postSkillId
      this.formData.postId = data.postId
      if (!this.mode) {
        this.skillList.push({ name: data.name, id: data.abilityId })
        this.formData.subSkillId = data.postSkillId
        this.formData.description = data.description
        this.formData.abilityId = data.abilityId
        this.formData.weight = data.weight
        this.formData.qualifiedRate = this.$floatMultiply(data.qualifiedRate, 100)
        this.formData.unqualifiedRemark = data.unqualifiedRemark
      } else {
        this.getAbilityListByOrganization()
      }
    },
    submit() {
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          let payload = { ...this.formData, qualifiedRate: this.formData.qualifiedRate / 100 }
          newPostApi
            .updateSubSkillInfo(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success(`${this.mode ? '添加' : '编辑'}子技能成功`)
                this.visible = false
                this.$emit('refresh', this.formData.postId)
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.skillList = []
      this.formData = {
        abilityId: '',
        weight: '',
        description: '',
        qualifiedRate: '',
        unqualifiedRemark: '',
        mainSkillId: '',
        subSkillId: '',
        postId: ''
      }
      this.$refs.form.resetFields()
    },
    getAbilityListByOrganization() {
      abilityApi.getAbilityListByOrganization(this.formData.mainSkillId).then(res => {
        this.skillList = res.res
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-textarea {
  .el-textarea__inner {
    height: auto;
  }
}
::v-deep .el-input-number {
  .el-input__inner {
    text-align: left;
  }
}
.rate-input {
  margin: 0 9px;
  &.el-input-number {
    width: 120px;
    ::v-deep .el-input {
      width: 100%;
    }
  }
}
</style>
