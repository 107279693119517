<template>
  <el-dialog
    :visible.sync="visible"
    title="添加课程"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="添加课程" prop="courseId">
        <el-select v-model="formData.courseId" placeholder="请选择课程" @change="getChapter" filterable>
          <el-option v-for="item in courseList" :key="item.courseId" :label="item.courseName" :value="item.courseId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="全部章节">
        <div style="height: 40px;display: flex; align-items: center">
          <el-switch v-model="formData.allConfigured" active-color="#448BFF" />
        </div>
      </el-form-item>
      <template v-if="!formData.allConfigured">
        <el-form-item label="添加章节" prop="chapterIds">
          <el-select v-model="formData.chapterIds" placeholder="请选择章节" multiple filterable>
            <el-option v-for="item in chapterList" :key="item.chapterId" :label="item.chapterName" :value="item.chapterId"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import newPostApi from '@api/newPost'
import courseApi from '@api/course'
import chapterApi from '@api/chapter'
import YTIcon from '@components/common/YTIcon'
export default {
  name: 'PostSkillCourseConfigDialog',
  components: { YTIcon },
  data() {
    return {
      visible: false,
      mode: true,
      formData: {
        courseId: '',
        postSkillId: '',
        allConfigured: false,
        chapterIds: [],
        type: 0 //0 添加 1 修改
      },
      ruleValidate: {
        courseId: [{ required: true, message: '请选择课程', trigger: 'change' }],
        chapterIds: [{ required: true, message: '请选择章节', trigger: 'change' }]
      },
      loading: false,
      courseList: [],
      chapterList: [],
      activeIndex: '' //添加课程的子技能下标
    }
  },
  methods: {
    open(data, index) {
      this.visible = true
      this.formData.postSkillId = data.postSkillId
      this.getCourse(data.courseList)
      this.activeIndex = index
    },
    submit() {
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          newPostApi
            .updateSubSkillCourse(this.formData)
            .then(res => {
              if (res.code === 0) {
                this.$message.success(`添加推荐课程成功`)
                this.visible = false
                let chapters = this.chapterList
                if (!this.formData.allConfigured) {
                  chapters = this.chapterList.filter(chapter => {
                    return this.formData.chapterIds.includes(chapter.chapterId)
                  })
                }
                let node = {
                  id: this.formData.courseId,
                  label: this.courseList.filter(course => {
                    return course.courseId === this.formData.courseId
                  })[0].courseName,
                  children: chapters.map(chapter => {
                    return {
                      id: chapter.chapterId,
                      label: chapter.chapterName
                    }
                  })
                }
                this.$emit('refresh', { index: this.activeIndex, node: node })
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.courseList = []
      this.chapterList = []
      this.formData = {
        courseId: '',
        postSkillId: '',
        allConfigured: false,
        chapterIds: [],
        type: 0 //0 添加 1 修改
      }
      this.$refs.form.resetFields()
    },
    getCourse(list) {
      //获取课程菜单
      courseApi.getCourseName().then(res => {
        this.courseList = res.res.filter(course => {
          return !list.some(item => {
            return item.id === course.courseId
          })
        })
      })
    },
    getChapter(value) {
      //获取课程下章节
      this.formData.chapterIds = []
      chapterApi.getChapterSimpleByCourseId(value).then(res => {
        this.chapterList = res.res
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-select {
  ::v-deep .el-tag {
    &:hover {
      background-color: #edf4ff;
      color: #448bff;
      .el-tag__close {
        background-color: #448bff;
      }
    }
  }
  ::v-deep .el-tag__close {
    color: #ffffff !important;
  }
}
</style>
